import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Router } from "@reach/router"
import Layout from 'components/layout';
import SEO from "components/seo";

import ImageUpload from "clientPages/imageUpload";
import SelectPlan from "clientPages/selectPlan";
import OrderComplete from "clientPages/orderComplete";

const GetStarted = () => {
    
    const data = useStaticQuery(graphql`
        query {
            contentfulLayout(title: {eq: "Cinder"}) {
                id
                title
                description {
                    description
                }
                contentful_id,
                menu {
                    name
                    type
                    menuItems {
                        id
                        title
                        url
                    }
                },
                contentModule {
                    ... on Node {
                        id
                    }
                }
            }
        }
    `);

    const menus = data.contentfulLayout.menu;
    const contentModule = data.contentfulLayout.contentModule;
    const apiUrl = process.env.API_URL;

    let userData = {}

    const setUserData = ( updatedUserData ) => {
        userData = updatedUserData
    }

    return (
        <Layout menus={ menus }>
            <SEO title="Get Cinder" />
            <Router basepath="/getstarted">
                <ImageUpload path="/uploadimages" apiUrl={apiUrl} userData={userData} setUserData={setUserData} default/>
                <SelectPlan path="/selectplan" apiUrl={apiUrl} userData={userData} setUserData={setUserData} />
                <OrderComplete path="/ordercomplete" apiUrl={apiUrl} userData={userData} setUserData={setUserData} />
            </Router>
        </Layout>
    );
};

export default GetStarted;

