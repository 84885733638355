import React, { useState, useEffect, useRef } from 'react';
import { navigate } from "@reach/router";
import { parse } from "query-string"
import { Link } from 'gatsby';
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

import Error from 'components/error'
import Button from 'components/button'

let locStorage

if (typeof window === "undefined" || window === null) {
    locStorage = require('localstorage-memory');
} else {
    locStorage = localStorage;
}

const ImageUpload = ({ apiUrl, location }) => {

    const [filesToUpload, setFilesToUpload] = useState({});
    const [uploadSuccess, setUploadSuccess] = useState("");
    const [error, setErrorString] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [imageCollection, setImageCollection] = useState({})
    const imageCollectionRef = useRef();

    imageCollectionRef.current = imageCollection
    // Check localstorage to see if userUUID exists
    // If it doesn't generate a new userUUID

    let userUUID = ""
    const params = parse( location.search )

    if(locStorage.getItem("userUUID")){
        userUUID = locStorage.getItem("userUUID")
    } else {
        // generate userUUID and save to locStorage
        userUUID = uuidv4();
        locStorage.setItem("userUUID", userUUID)
    }

    useEffect(() => {
        if(imageCollection && Object.keys(imageCollection).length == 0 && locStorage.getItem("userImages")){
        
            let existingImageString = locStorage.getItem("userImages")
            let existingImages = {}
            
            try {
                existingImages = JSON.parse(existingImageString)
            } catch (error) {
                locStorage.removeItem("userImages")
                console.error('Error parsing saved images')
            }
            
            setImageCollection(existingImages)
        }
    }, []);

    const setError = (errorString) => {
        gtag('event', 'exception', {
            description: errorString,
            fatal: true
        });
        setErrorString(errorString)
    }

    const pollImageStatus = (key, userUUID, imageUUID, timerId) => {
        axios(
            `${apiUrl}/check-image-status?userUUID=${userUUID}&imageUUID=${imageUUID}`
                
        ).then(response => {
            console.log(response)
            if(response.data.imageEntry.ProcessingStatus.S == "success_face_detected"){
                const alreadySuccess = imageCollectionRef.current[key].success
                const updatedImageCollection = {
                    ...imageCollectionRef.current,
                    [key]: {
                        ...imageCollectionRef.current[key],
                        error: false,
                        loading: false,
                        success: true,
                        status : "success_face_found",
                        statusMessage : "Face Found"
                    }
                }
                setImageCollection(updatedImageCollection)

                // If all successful automatically advance
                if(!alreadySuccess){
                    handleNextStep (false, updatedImageCollection)
                }
                clearInterval(timerId)
                
            } else if(response.data.imageEntry.ProcessingStatus.S == "error_multiple_faces_detected") {
                setImageCollection({
                    ...imageCollectionRef.current,
                    [key]: {
                        ...imageCollectionRef.current[key],
                        error: true,
                        loading: false,
                        status : "error_multiple_faces_detected",
                        statusMessage : "Multiple Faces in Image"
                    }
                })
                clearInterval(timerId)
            } else {
                setImageCollection({
                    ...imageCollectionRef.current,
                    [key]: {
                        ...imageCollectionRef.current[key],
                        error: true,
                        loading: false,
                        status : "error_face_not_found",
                        statusMessage : "Face Not Found"
                    }
                })
                clearInterval(timerId)
            }
            
        }).catch(err => {
            console.log(err)
            setImageCollection({
                ...imageCollectionRef.current,
                [key]: {
                    ...imageCollectionRef.current[key],
                    error: true,
                    loading: false,
                    status : "error_polling",
                    statusMessage : "Server Error.  Please try again!"
                }
            })
            clearInterval(timerId)
        })
    }

    const handleImageDelete = (e, key) => {
        e.preventDefault();

        delete imageCollection[key]
        locStorage.setItem("userImages", JSON.stringify(imageCollection))
        setImageCollection({ ...imageCollection })
        setError("")    
    }

    const handleNextStep = (e, updatedImageCollection) => {
        if(e) { e.preventDefault();}

        let currentImageCollection = updatedImageCollection
        if(!updatedImageCollection){
            currentImageCollection = imageCollection
        }
        
        setIsLoading(true)
        // Save imageCollection in local storage
        locStorage.setItem("userImages", JSON.stringify(currentImageCollection))

        // handle validation
        performFinalImageCheck(
            () => { navigate(`/getstarted/selectplan`, { state: params }) }, 
            () => { 
                setIsLoading(false);
                setError("We were unable to confirm that all uploaded images were of the same person, or we've identified some duplicate images.  Please upload different images and try again!");
        })
    }

    // Start polling for image processing completetion
    useEffect(() => {
        Object.keys(imageCollection).forEach( key => {
            // repeat with the interval of 5 seconds
            if(imageCollection[key].status == 'success_image_upload'){
                let timerId = setInterval(() => {
                    pollImageStatus(key, userUUID, imageCollection[key].imageUUID, timerId)
                }, 5000);

                setTimeout(() => { clearInterval(timerId); }, 20000);
            }
        } )
    }, [imageCollection]);

    useEffect( () => {
        if(filesToUpload && Object.keys(filesToUpload).length){
            
            Object.keys(filesToUpload).forEach( (key) => {
                uploadFile(key)

                delete filesToUpload[key]
                
                setFilesToUpload({
                    ...filesToUpload
                })
            } )
        }
    }, [filesToUpload] )

    const uploadFile = (key) => {
        // When the upload file button is clicked, 
        // first we need to get the presigned URL
        // URL is the one you get from AWS API Gateway

        gtag("event", "checkout_progress", {
            currency: "USD",
            checkout_step: 1,
            value: 0,
            items: [
            ]
          });

        const fileToUpload = filesToUpload[key]
        const imageUUID = uuidv4()

        setImageCollection({
            ...imageCollectionRef.current,
            [key]: {
                ...imageCollectionRef.current[key],
                error: false,
                loading: true,
                success: false,
                imageUUID: imageUUID,
                status : "pending_upload_url",
                statusMessage : "Waiting for Upload URL"
            }
        })

        axios(
            `${apiUrl}/presigned-url?fileName=${fileToUpload.name}&userUUID=${userUUID}&imageUUID=${imageUUID}`
        ).then(response => {
            // Getting the url from response
            console.log(response)
            
            setImageCollection({
                ...imageCollectionRef.current,
                [key]: {
                    ...imageCollectionRef.current[key],
                    error: false,
                    loading: true,
                    success: false,
                    imageUUID: imageUUID,
                    status : "success_upload_url",
                    statusMessage : "Received Image Upload URL"
                }
            })
            
            const url = response.data.fileUploadURL;
            const imageURL = response.data.fileReadURL;
            
            // Initiating the PUT request to upload file    
            axios({
                method: "PUT",
                url: url,
                data: fileToUpload,
                headers: { "Content-Type": "multipart/form-data", "x-amz-meta-userUUID": userUUID, "x-amz-meta-imageUUID": imageUUID }
            })
                .then(res => {
                    setUploadSuccess("File upload successfull")
                    setError(undefined)

                    const fileURL = url.split('?')

                    setImageCollection({
                        ...imageCollectionRef.current,
                        [key]: {
                            ...imageCollectionRef.current[key],
                            error: false,
                            loading: true,
                            success: false,
                            imageUUID: imageUUID,
                            uploadURL: fileURL[0],
                            imageURL: imageURL,
                            status : "success_image_upload",
                            statusMessage : "Image Uploaded, Waiting for Faces!"
                        }
                    })
                })
                .catch(err => {
                    console.log(err);
                    setUploadSuccess("undefined")
                    setIsLoading(false);
                    setError("Error Occured while uploading the file")
                });
        }).catch(err => {
            setImageCollection({
                ...imageCollectionRef.current,
                [key]: {
                    ...imageCollectionRef.current[key],
                    error: true,
                    loading: false,
                    success: false,
                    imageUUID: imageUUID,
                    status : "error_getting_upload_url",
                    statusMessage : "Error Getting Upload URL.  Please Try Again!"
                }
            })
        })
    }

    const performFinalImageCheck = (successCallback, errorCallback) => {
        axios(
            `${apiUrl}/check-faces?imageIds=${imageCollection["1"].imageUUID}`
        ).then(response => {
            if(response?.data?.status == "error"){
                if(response.data.error_type == "unmatched_faces"){
                    const errorImageUUIDs = response.data.ImageUUIDs

                    const errorKeys = []
                    errorImageUUIDs.forEach ( (imageUUID, key) => {
                        errorKeys.push(Object.keys(imageCollectionRef.current).find( key => {
                            return imageCollectionRef.current[key].imageUUID == imageUUID
                        } ))
                    })

                    errorKeys.forEach( key => {
                        setImageCollection({
                            ...imageCollectionRef.current,
                            [key]: {
                                ...imageCollectionRef.current[key],
                                error: true,
                                loading: false,
                                success: false,
                                status : "error_face_not_matching",
                                statusMessage : "Face Doesn't Match"
                            }
                        })
                    })

                    errorCallback()
                }
            }
            else if(response?.data?.status == "success"){
                successCallback()
            }
        }).catch(err => {
            console.error(err);
        })
    }

    const getImage = (image, key) => {
        return (
            <div className="uploaded-image inline-block w-full h-64 bg-gray-200 rounded shadow-lg bg-white">
                {
                    !image.loading && (
                        <div 
                            className="close-wrapper cursor-pointer pd-3"
                            onClick={ (e) => {
                                handleImageDelete(e, key)
                            } }

                        >
                            <i className="item__icon mr-2 mt-2 text-white material-icons">close</i>
                        </div>
                    )
                }
                {
                    image.loading && (
                        <div className="loading-wrapper">
                            <svg className="stroke-current text-pink" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="50" cy="50" r="45"/>
                            </svg>
                        </div>
                    )
                }
                <div className="image-wrapper">
                    {
                        image.error && (
                            <span className="status-message bg-red-600 bg-opacity-75">
                                <span className="text-xl text-white px-4">
                                    {image.statusMessage}
                                </span>
                            </span>
                        )
                    }
                    {
                        image.success && (
                            <span className="status-message bg-primary bg-opacity-75">
                                <span className="text-xl text-white px-4">
                                    {image.statusMessage}
                                </span>
                            </span>
                        )
                    }
                    {
                        !image.loading && image.imageURL && (
                            <img className={image.error ? 'error' : ''} src={image.imageURL} />
                        )
                    }
                    
                </div>
            </div>
        )
    }

    const validImageCount = Object.keys(imageCollection).filter( key => imageCollection[key].status == 'success_face_found' ).length

    return (
        <div id="image-upload" class="container mx-auto">
            <div className="w-full rounded shadow-lg bg-white">
                <form className="bg-white shadow-md rounded p-12 mb-8">
                    <div className="">
                        <div className="font-bold text-2xl mb-2">Step 1: Upload Image</div>
                        <p className="text-gray-700 text-base">
                        Upload an images that contain the face of the person you want to search for.
                        </p>
                        <div className="font-semibold mt-4">
                            Make sure your image:
                        </div>
                        <ul className="list-disc ml-6">
                            <li>Clearly displays a person's face.</li>
                            <li>Is in focus and is high resolution.</li>
                            <li>Only contains ONE face.  No group pictures!</li>
                        </ul>
                    </div>
                    <div className="pt-8">
                        <div className="grid grid-cols-1 md:grid-cols-3 my-2 border-b border-t border-lightGray">
                            <div></div>
                            { ["1"].map( key => {
                                if(imageCollection[key]) {
                                    return (
                                        <div className="w-full m-auto p-6">
                                            { getImage(imageCollection[key], key) }
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <div className=" w-full pt-16 h-64 bg-grey-lighter">
                                            <label for={`upload-${key}`} className="w-64 flex flex-col items-center m-auto px-4 py-6 bg-white rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:text-pink">
                                                <svg className="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                                </svg>
                                                <span className="mt-2 text-base leading-normal">Select Photo</span>
                                                <input 
                                                    type='file' 
                                                    id={`upload-${key}`}
                                                    style={{visibility: "hidden", height: "1px"}} 
                                                    accept="image/*"
                                                    onChange={e => {
                                                        setFilesToUpload({ ...filesToUpload, [key]: e.target.files[0] });
                                                    }}
                                                />
                                            </label>
                                        </div>        
                                    )
                                }
                            } )}
                        </div>
                    </div>
                    <div className="mb-6">
                        <Error message={error} />
                    </div>
                    <Button
                        isDisabled={!(validImageCount == 1 )}
                        isLoading={isLoading}
                        onClick={e => {
                            handleNextStep (e);
                        }}
                        text="Next Step"
                    /> 
                </form>
            </div>
        </div>
    );
};

export default ImageUpload;

