import React, { useState, useEffect } from "react";
import { navigate, useParams } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";
import sal from "sal.js";

import PricingPlan from "components/pricingPlan";
import AboutItem from "components/aboutItem";

let locStorage

if (typeof window === "undefined" || window === null) {
    locStorage = require('localstorage-memory');
} else {
    locStorage = localStorage;
}

const OrderComplete = ({ apiUrl, location }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPricingPlan(sort: { order: ASC, fields: order }) {
        edges {
          node {
            id
            price
            currency
            perItem
            planFeatures {
              __typename
              ... on ContentfulFeatureItem {
                id
                title
                icon
                description {
                  description
                }
              }
            }
            title
            featured
            planId
            buttonText
          }
        }
      }
    }
  `);

  useEffect(() => {
    sal();
  });

  console.log(location.state.planId)
  let selectedPlanId = location.state?.planId;

  const { node:selectedPricingPlan } = data.allContentfulPricingPlan.edges.find(edge => {
    return edge.node.id == selectedPlanId;
  });

  // Check localstorage to see if userUUID exists
  let userUUID = "";
  if (locStorage.getItem("userUUID")) {
    userUUID = locStorage.getItem("userUUID");
  } else {
    // generate userUUID and save to localstorage
    console.error("userUUID isn't set");
  }

  return (
    <div class="container mx-auto">
      <div className="w-full grid grid-cols-3">
        <div className="col-span-3">
          <div className="w-full bg-white shadow-lg rounded p-12 mb-8">
            <div className="font-bold text-2xl mb-2">Order Complete!</div>
            <p className="text-gray-700 text-base mb-2">
              We're processing your order and will email you the results of our first search (even if we don't find any matches) within 24 hours.
            </p>
            <b>This email will also contain:</b>
            <ul className="list-disc ml-4">
              <li>A recap of your order</li>
              <li>A link to manage your account</li>
            </ul>
            <p className="text-gray-700 text-base mt-4">
              If you have any questions, please feel free to contact us through email at <a href="mailto:contact@getcinder.com">contact@getcinder.com</a> 
            </p>
            { selectedPricingPlan && (
              <div>
                <div className="font-bold text-xl mb-2 mt-4">You've Ordered:</div>
                { selectedPricingPlan.title }
              </div>
            ) }
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderComplete;
