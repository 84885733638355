import React from 'react';

const Error = ( { message } ) => {

    if(message?.length > 0){
        return (
            <div className="rounded px-10 py-6 my-3 bg-red-600 bg-opacity-75 text-white font-medium">
                {message}
            </div>
                                   
        );
    }
    else {
        return null
    }
};

export default Error;

