import React, { useState, useEffect } from 'react';

const Input = ( props ) => {

    useEffect(() => {});

    // Check for error first
    const errorClasses = ( props.error && 'input--error' )

    if(props.type == "checkbox"){
        return (
            <div className={props.className || props.class} data-sal="fade" data-sal-easing="ease-in-cubic" data-sal-duration="400">
                <input 
                    className={`shadow ${errorClasses}`}
                    id={props.name}
                    name={props.name}
                    type={"checkbox"}
                    value={props.value}
                    ref={props.inputRef}
                    onChange={ (e) => {
                        if(props.onChange){
                            e.preventDefault();
                            props.onChange(props.name, e.currentTarget.value);
                        }
                    } }
                />
                <label className={`block text-gray-700 text-sm font-bold ml-2 ${errorClasses}`} for={props.name}>
                    {props.label || props.children}
                </label>
            </div>
        )
    }

    return (
        <div className={props.className || props.class} data-sal="fade" data-sal-easing="ease-in-cubic" data-sal-duration="400">
            <label className="block text-gray-700 text-sm font-bold mb-2" for={props.name}>
                {props.label}
            </label>
            <input 
                className={`shadow appearance-none border rounded w-full py-2 px-3 mb-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errorClasses}`}
                id={props.name}
                name={props.name}
                type={props.type || "text"}
                value={props.value}
                ref={props.inputRef}
                placeholder={props.placeholder || props.label} 
                onChange={ (e) => {
                    if(props.onChange){
                        e.preventDefault();
                	    props.onChange(props.name, e.currentTarget.value);
                    }
                } }
            />
            { 
            	props.error &&
            	<span>
            		{ props.error.message.message }
            	</span>
            }
        </div>
                               
    );
};

export default Input;

