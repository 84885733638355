import React, { useState, useEffect, useCallback, useRef } from "react";
import { navigate, useParams } from "@reach/router";
import axios from "axios";
import { useStaticQuery, graphql } from "gatsby";
import { v4 as uuidv4 } from "uuid";
import sal from "sal.js";

import PricingPlan from "components/pricingPlan";
import AboutItem from "components/aboutItem";
import Input from "components/input";
import Button from 'components/button';
import LocationSuggest from 'components/locationSuggest';

import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { loadStripe } from "@stripe/stripe-js";

import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

// Check localstorage to see if userUUID exists
// If it doesn't generate a new userUUID
let locStorage

if (typeof window === "undefined" || window === null) {
    locStorage = require('localstorage-memory');
} else {
    locStorage = localStorage;
}

let userUUID = "";

if (locStorage.getItem("userUUID")) {
  userUUID = locStorage.getItem("userUUID");
} else {
  // generate userUUID and save to locStorage
  console.error("userUUID isn't set");
}

const CheckoutForm = props => {
  const stripe = useStripe();
  const elements = useElements();

  const [error, setErrorString] = useState("");
  const [cardElementComplete, setCardElementComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    // checkLocation: yup.boolean(),
    // location: yup.string().when("checkLocation", {
    //   is: val => {
    //     return ["oneTime", "oneLocation"].includes(props.selectedPricingPlan.planId);
    //   },
    //   then: yup.string().required({ message: "Select a Location" }),
    //   otherwise: yup.string(),
    // }),
    name: yup.string().required({ message: "Enter Your Name" }),
    tos: yup.boolean().oneOf([true], { message: "Please accept our Terms of Service to Proceed" }),
    email: yup
      .string()
      .email({ message: "Enter a Valid Email Address" })
      .required({ message: "Enter Your Email" }),
  });

  const { register, handleSubmit: formHandleSubmit, errors } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  }); // initialize the hook

  const setError = (errorString) => {
    gtag('event', 'exception', {
      description: errorString,
      fatal: true
    });
    setErrorString(errorString)
  }

  const stripeHandleSubmit = async (event, form) => {
    gtag("event", "add_payment_info")
    const {
      error: paymentError,
      paymentMethod,
    } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (paymentError) {
      setIsLoading(false);
      setError(paymentError);
    } else if (!paymentMethod || !paymentMethod.id) {
      setIsLoading(false);
      setError("Error creating payment method");
    } else if (paymentMethod?.id) {
      // send everything to the backend
      const response = await axios
        .post(`${props.apiUrl}/complete-purchase`, {
          paymentId: paymentMethod.id,
          userUUID: userUUID,
          selectedPricingPlanId: props.selectedPricingPlan.planId,
          selectedLocation: props.selectedLocation,
          ...form,
        })
        .then(({ data, status }) => {
          if (status == 200 && data.success == true) {
            // Fire Purchase Tracking Events
            fbq('track', 'Purchase', {currency: "USD", value: props.selectedPricingPlan.price, title: props.selectedPricingPlan.price});
            gtag("event", "purchase", {
              currency: "USD",
              value: props.selectedPricingPlan.price,
              items: [
                {
                  id: props.selectedPricingPlan.id,
                  name: props.selectedPricingPlan.title,
                  category: props.selectedPricingPlan.perItem,
                  quantity: 1,
                  price: props.selectedPricingPlan.price
                }
              ]
            });
            
            navigate(`/getstarted/ordercomplete`, {
              state: { planId: props.selectedPricingPlan.id },
            });
          } else {
            console.error(data.error);
            setIsLoading(false);
            setError(data.errorMessage);
          }
        })
        .catch(error => {
          console.error(error);

          let errorMessage = "There was an error processing your order.  Please try again later."
          if (error.response) {
            errorMessage = error.response?.data?.errorMessage
          }

          setIsLoading(false);
          setError(errorMessage);
        });
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setIsLoading(true);

    if(!cardElementComplete){
      setError("Please Fill Out Your Payment Information.")
      setIsLoading(false);
      return false
    }
    // clear errors
    setError("");

    const onSubmit = form => {
      stripeHandleSubmit(event, form);
    };

    const onError = err => {
      setIsLoading(false);
      console.error(err);
    };

    formHandleSubmit(onSubmit, onError)(event);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="font-bold text-xl mb-2">3. Enter Payment Info</div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-0 md:gap-4">
        <Input
          error={errors.name}
          className="col-span-1"
          label="Your Name"
          placeholder="Name"
          name="name"
          inputRef={register}
        />
        <Input
          error={errors.email}
          className="col-span-1"
          label="Your Email"
          placeholder="Email"
          name="email"
          inputRef={register}
        />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        <div className="col-span-2 ">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            for="payment_info"
          >
            Payment Info
          </label>
          <CardElement
            data-sal="fade"
            data-sal-easing="ease-in-cubic"
            data-sal-duration="400"
            options={{ style: { base: { fontSize: "16px" } } }}
            name="payment_info"
            onChange={(el) => {
              setCardElementComplete(el.complete);
              if(el.complete){
                setError("");
              }
            }}
            className="shadow appearance-none border rounded w-full py-2 px-3 mb-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        {error.length > 0 && (
          <div className="col-span-2 p-6 bg-red-400 text-white rounded shadow">{error}</div>
        )}
      </div>
      <div className="grid grid-cols-3 md:grid-cols-6 gap-4 py-4">
        <span className="col-span-3 md:col-span-2 p-1">
          <Input 
            className="flex flex-row items-center"
            type="checkbox"
            value="true" 
            inputRef={register}
            name="tos" 
            error={errors.tos}
          >
            I accept the <a className="underline" href="/termsofservice" target="__blank">Terms of Service</a>
          </Input>
        </span>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-6 gap-4 py-4">
        <Button
          isDisabled={(Object.keys(errors).length)}
          isLoading={isLoading}
          onClick={e => {}}
          type="submit"
          text="Submit"
        /> 
      </div>
    </form>
  );
};

const stripePromise = loadStripe(process.env.STRIPE_PK);

const SelectPlan = ({ apiUrl, location }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPricingPlan(sort: { order: ASC, fields: order }) {
        edges {
          node {
            id
            price
            currency
            perItem
            planFeatures {
              __typename
              ... on ContentfulFeatureItem {
                id
                title
                icon
                description {
                  description
                }
              }
            }
            title
            description {
              childMarkdownRemark {
                  html
              }
            }
            featured
            planId
            buttonText
          }
        }
      }
    }
  `);

  useEffect(() => {
    sal();
  });

  useEffect(() => {
    gtag("event", "checkout_progress", {
      currency: "USD",
      checkout_step: 2,
      value: 0,
      items: [
      ]
    });
  }, [])

  const [selectedLocation, setSelectedLocation] = useState(location.state?.location);
  const [selectedPlanId, setSelectedPlanId] = useState(
    location.state && location.state.plan
  );
  const [selectedPricingPlan, setSelectedPricingPlan] = useState();
  const [imageCollection, setImageCollection] = useState({});

  useEffect(() => {
    const selectedPlan = data.allContentfulPricingPlan.edges.find(edge => {
      return edge.node.planId == selectedPlanId;
    });

    if (selectedPlan && selectedPlan.node) {
      const selectedPlanCopy = { ...selectedPlan.node };
      selectedPlanCopy.buttonText = "Change Plan";
      setSelectedPricingPlan(selectedPlanCopy);

      gtag("event", "checkout_progress", {
        currency: "USD",
        checkout_step: 3,
        value: 0,
        items: [
          {
            id: selectedPlanCopy.id,
            name: selectedPlanCopy.title,
            category: selectedPlanCopy.perItem,
            quantity: 1,
            price: selectedPlanCopy.price
          }
        ]
      });
    } else {
      setSelectedPricingPlan();
      gtag("event", "checkout_progress", {
        currency: "USD",
        checkout_step: 3,
        value: 0,
        items: []
      });
    }
  }, [selectedPlanId]);

  return (
    <div class="container mx-auto">
      <div className="w-full grid grid-cols-3">
        <div className="col-span-3">
          <div className="w-full bg-white shadow-lg rounded px-6 py-12 md:px-12 mb-8">
            <div className="font-bold text-2xl mb-2">You're Almost Done!</div>
            <p className="text-gray-700 text-base mb-4">
              We've processed your upload and everything is ready to go!
            </p>
            {
              (selectedLocation && selectedLocation != "undefined") && (
                <div className="font-bold text-xl mb-4">
                  1. Confirm Your Location
                </div>
              )
            }
            {
              (selectedLocation && selectedLocation != "undefined") && (
                <div className="relative grid grid-cols-1 md:grid-cols-1 gap-4 mb-4">
                  <p className="text-gray-700 text-base">
                    {selectedLocation} - <a className="cursor-pointer font-semibold" onClick={() => setSelectedLocation(null)}>Change Location</a>
                  </p>
                  { (selectedLocation == "Los Angeles, CA, USA") && (
                    <div className="bg-green-200 px-6 py-4 rounded flex items-center">
                    <i className="item__icon material-icons w-10">checkbox</i>
                    <div className="flex-grow">
                      We've scanned this location in the last 24 hours!
                    </div>
                  </div>
                  )}
                </div>
              )
            }
            {
              (!selectedLocation || selectedLocation == "undefined") && (
                <div className="font-bold text-xl mb-4">
                  1. Select a Location
                </div>
              ) 
            }
            {
              (!selectedLocation || selectedLocation == "undefined") &&
              (
                <div className="relative grid grid-cols-1 md:grid-cols-3 gap-4">
                  <LocationSuggest
                    wrapperClass="mb-4"
                    inputClass="w-full border py-1 px-2 rounded"
                    placeholder="Select a Location"
                    onLocationSelect={(locationObject) => { setSelectedLocation(locationObject.label) }}
                  />
                </div>
              )
            }
            {selectedPricingPlan && (
              <div>
                <div className="font-bold text-xl mb-4">
                  2. Confirm Your Plan:
                </div>
                <div className="flex flex-col md:flex-row justify-start md:mb-4">
                  <PricingPlan
                    onClick={() => {
                      setSelectedPlanId();
                    }}
                    plan={selectedPricingPlan}
                    featured={true}
                    key={selectedPricingPlan.id}
                  />
                  {selectedPricingPlan.planFeatures.length > 0 && (
                    <ul className="mt-10 md:ml-8">
                      {selectedPricingPlan.planFeatures.map(feature => (
                        <AboutItem feature={feature} key={feature.id} />
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            )}
            {!selectedPricingPlan && (
              <div>
                <div className="font-bold text-xl mb-4 pb-2 border-b border-lightGray">
                  2. Select Plan
                </div>
                <div className="pricing__items">
                  {data.allContentfulPricingPlan.edges.map(pricingPlan => (
                    <PricingPlan
                      onClick={() => {
                        setSelectedPlanId(pricingPlan.node.planId);
                      }}
                      plan={{ ...pricingPlan.node, buttonText: "Select Plan" }}
                      featured={true}
                      key={pricingPlan.node.id}
                    />
                  ))}
                </div>
              </div>
            )}
            {selectedPricingPlan && (
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  apiUrl={apiUrl}
                  selectedLocation={selectedLocation}
                  selectedPricingPlan={selectedPricingPlan}
                />
              </Elements>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectPlan;
