import React from 'react';

const Button = ( { type, text, isDisabled, isLoading, onClick } ) => {

    return (    
        <button
            type={`${type ? type : 'button'}`}
            className={`btn bg-pink ${ (isLoading || isDisabled) && 'disabled' }`}
            onClick={e => {
                onClick(e);
            }}
        > 
            {
                `${( isLoading ? 'Processing...' : text )}`
            }
        </button>
    )
};

export default Button;

